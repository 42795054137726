<template>
  <div class='about'>
    <img class="img" src="@/assets/about/20170327044850127.png" alt="">
    <div class="list">
      <h1 class="jianjie">公司简介</h1>
      <div class="trus">
        <img class='trus_img' src="@/assets/about/20170327040236954.png" alt="">
      </div>
      <br>
      <br>
      <div class="foor">
        <img class="foor_img" src="@/assets/about/20170327035011892.jpg" alt="">
        <div class="foor_text">
          <div style="text-indent: 2em;">
            2014年10月，北京数字睿信移动技术有限公司在北京注册成立。公司拥有一流的技术团队，专注于移动信息化,为政企量身打造最佳的整体解决方案；是政企在移动信息化建设方面的首选。
          </div>
          <div style="text-indent: 2em;">
             作为一家专注移动信息化的国家高新技术企业，得益于在政府、通信、制造、金融、医疗、交通等多个领域的沉淀，结合各行业移动信息化战略发展方向，将移动互联创新成果深度融合到各个领域之中， 客户行业包括了政府、交通、教育、金融、媒体、医疗、通信等众多行业，并获得市场一致好评。
          </div>
          <div style="text-indent: 2em;">
            北京数字睿信移动技术有限公司是一家基于移动混合云架构服务平台，通过内部互联与外部创新，形成一套整合、完备的移动化解决方案，帮助客户从梳理业务痛点、行业诉求及价值需求出发，到敏捷开发，数据整合， 移动中间件平台保障，多环境部署管理，再到后续运维升级，行成完美闭环。公司旨在帮助政企建立专属的级移动应用商店，让更多政企快速高效的接入移动互联网，实现从移动互联协同时代到生态圈的跨变。
          </div>
        </div>
      </div>
      <h1 class="jianjie">公司简介</h1>
      <div class="foor_text foor1">
        <div style="text-indent: 2em;">
          2014年10月，北京数字睿信移动技术有限公司在北京注册成立。公司拥有一流的技术团队，专注于移动信息化,为政企量身打造最佳的整体解决方案；是政企在移动信息化建设方面的首选。
        </div>
        <br>
        <br>
        <div style="text-indent: 2em;">
          作为一家专注移动信息化的国家高新技术企业，得益于在政府、通信、制造、金融、医疗、交通等多个领域的沉淀，结合各行业移动信息化战略发展方向，将移动互联创新成果深度融合到各个领域之中， 客户行业包括了政府、交通、教育、金融、媒体、医疗、通信等众多行业，并获得市场一致好评。
        </div>
        <br>
        <br>
        <div style="text-indent: 2em;">
          北京数字睿信移动技术有限公司是一家基于移动混合云架构服务平台，通过内部互联与外部创新，形成一套整合、完备的移动化解决方案，帮助客户从梳理业务痛点、行业诉求及价值需求出发，到敏捷开发，数据整合， 移动中间件平台保障，多环境部署管理，再到后续运维升级，行成完美闭环。公司旨在帮助政企建立专属的级移动应用商店，让更多政企快速高效的接入移动互联网，实现从移动互联协同时代到生态圈的跨变。
        </div>
      </div>
      <h1 class="jianjie">企业文化</h1>
      <div class="foor_text foor1">
        <div style="text-indent: 2em;">
          2014年10月，北京数字睿信移动技术有限公司在北京注册成立。公司拥有一流的技术团队，专注于移动信息化,为政企量身打造最佳的整体解决方案；是政企在移动信息化建设方面的首选。
        </div>
        <br>
        <br>
        <div style="text-indent: 2em;">
          作为一家专注移动信息化的国家高新技术企业，得益于在政府、通信、制造、金融、医疗、交通等多个领域的沉淀，结合各行业移动信息化战略发展方向，将移动互联创新成果深度融合到各个领域之中， 客户行业包括了政府、交通、教育、金融、媒体、医疗、通信等众多行业，并获得市场一致好评。
        </div>
        <br>
        <br>
        <div style="text-indent: 2em;">
          北京数字睿信移动技术有限公司是一家基于移动混合云架构服务平台，通过内部互联与外部创新，形成一套整合、完备的移动化解决方案，帮助客户从梳理业务痛点、行业诉求及价值需求出发，到敏捷开发，数据整合， 移动中间件平台保障，多环境部署管理，再到后续运维升级，行成完美闭环。公司旨在帮助政企建立专属的级移动应用商店，让更多政企快速高效的接入移动互联网，实现从移动互联协同时代到生态圈的跨变。
        </div>
      </div>
      <img class="trus_img" src="@/assets/about/20170424103226217.png" alt="">
      <div class="foor_text foor1">
        <div style="text-indent: 2em;">
          2014年10月，北京数字睿信移动技术有限公司在北京注册成立。公司拥有一流的技术团队，专注于移动信息化,为政企量身打造最佳的整体解决方案；是政企在移动信息化建设方面的首选。
        </div>
        <br>
        <br>
        <div style="text-indent: 2em;">
          作为一家专注移动信息化的国家高新技术企业，得益于在政府、通信、制造、金融、医疗、交通等多个领域的沉淀，结合各行业移动信息化战略发展方向，将移动互联创新成果深度融合到各个领域之中， 客户行业包括了政府、交通、教育、金融、媒体、医疗、通信等众多行业，并获得市场一致好评。
        </div>
        <br>
        <br>
        <div style="text-indent: 2em;">
          北京数字睿信移动技术有限公司是一家基于移动混合云架构服务平台，通过内部互联与外部创新，形成一套整合、完备的移动化解决方案，帮助客户从梳理业务痛点、行业诉求及价值需求出发，到敏捷开发，数据整合， 移动中间件平台保障，多环境部署管理，再到后续运维升级，行成完美闭环。公司旨在帮助政企建立专属的级移动应用商店，让更多政企快速高效的接入移动互联网，实现从移动互联协同时代到生态圈的跨变。
        </div>
      </div>
    </div>
    <img class="img" src="@/assets/about/20170425021648842.png" alt="">
    <div class="list">
      <h1 class="jianjie">企业资质</h1>
      <div class="jian_img">
        <div class="img_tiem">
          <div class="balk_img"><img src="@/assets/about/20170309022958661.jpg" alt=""></div>
          <span>企业微信管理软件证书</span>
        </div>
        <div class="img_tiem">
          <div class="balk_img"><img src="@/assets/about/20170309023710354.jpg" alt=""></div>
          <span>企业移动办公管理软件证书</span>
        </div>
        <div class="img_tiem">
          <div class="balk_img"><img src="@/assets/about/20170309024116771.jpg" alt=""></div>
          <span>企业移动驾驶舱管理软件证书</span>
        </div>
        <div class="img_tiem">
          <div class="balk_img"><img src="@/assets/about/20170309030825944.jpg" alt=""></div>
          <span>企业移动客户管理软件证书</span>
        </div>
        <div class="img_tiem">
          <div class="balk_img"><img src="@/assets/about/20170309030855619.jpg" alt=""></div>
          <span>企业移动维保管理软件证书</span>
        </div>
        <div class="img_tiem">
          <div class="balk_img"><img src="@/assets/about/20170309030932689.jpg" alt=""></div>
          <span>移动云平台管理软件证书</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'vue',
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
</script>

<style scoped lang='less'>
.jianjie{
  font-size: 0.2985rem;
}
.img{
  width: 100%;
}
.list{
  max-width: 8.9552rem;
  margin: 0 auto;
}
.trus{
  width: 100%;
  text-align: center;
  margin: 0 0.1493rem;

}
.foor{
  display: flex;
  margin-bottom: 1.4925rem;
  .foor_img{
    width: 2.5448rem;
    height: 1.6418rem;
  }

}
.foor_text{
    font-size: 0.1269rem;
    font-family:微软雅黑 ;
    margin-left: 0.0746rem;
  }
.foor1{
  margin: 0.7463rem 0;
}

.jian_img{
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.2985rem;
  .img_tiem{
      width: 25%;
    .balk_img{
      padding: 0.1493rem;
      img{
        max-width: 100%;
      }
    }
    span{
      padding-left: 0.1493rem;
      font-size: 0.1194rem;
    }
  }
}
  .trus_img{
      display: inline-block;
      width: 100%;
  }
</style>